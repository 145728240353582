import React, {FC, useEffect, useState} from 'react';
import {Segmented} from 'antd';
import {ChartState} from '../../types/Manitoring/ChartState';
import {Brush, LineChart, ResponsiveContainer} from 'recharts';
import {ChartData} from '../../types/Manitoring/ChartData';
import {getMonitoringMetrics} from '../../services/pages/monitoring';
import {useParams} from 'react-router';
import {MetricType} from '../../types/Manitoring/MetricType';
import styles from './Monitoring.module.scss';
import {getDays, getMinutes, getMonths} from '../../ChartUtils';

export type MonitoringControlComponentProps = {

    onChangePeriod: (period: string) => void;

};

export const MonitoringControlComponent: FC<MonitoringControlComponentProps> = ({
                                                                                    onChangePeriod,
                                                                                }) => {
    const {id} = useParams();
    const [chartData, setChartData] = useState<ChartData[]>([]);

    const [chartState, setChartState] = useState<ChartState>({
        period: 'MINUTE',
        lineColor: 'green',
        data: []
    });

    let segmentOpts = [
        {label: 'Месяцы', value: 'MONTH'},
        {label: 'Дни', value: 'DAY'},
        {label: 'Часы', value: 'HOUR'},
        {label: 'Минуты', value: 'MINUTE'}
    ]

    useEffect(() => {
        getChartData();
    }, []);

    useEffect(() => {
        getChartData();
    }, [chartState.period]);

    const getChartData = () => {
        getMonitoringMetrics(id || '', MetricType.disk, chartState.period, 100).then((resp) => {
            setChartData(resp.data);
            setChartState((prev) => ({...prev, data: resp.data}));
        });
    }

    const onChangeChartPeriod = (period: string) => {
        setChartState((prev) => ({...prev, period: period}));
        onChangePeriod(period);
    }

    const brush = (val: ChartData) => {
        switch (chartState.period) {
            case 'MINUTE':
                return getMinutes(val.t);
            case 'HOUR':
                return getMinutes(val.t);
            case 'DAY':
                return getDays(val.t);
            case 'MONTH':
                return getMonths(val.t);

        }
    }

    return (
        <div style={{marginTop: '10px', marginBottom: '10px'}}>
            <div>
                <Segmented<string>
                    options={segmentOpts}
                    defaultValue={chartState.period}
                    onChange={(value) => onChangeChartPeriod(value)}/>
            </div>
            <div className={styles.control_row}>
                <div style={{width: '1200px', height: '50px'}}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={100}
                            data={chartData}
                            syncId={'monitoring'}
                            margin={{
                                left: 5,
                            }}>

                            <Brush height={25}
                                   dataKey={brush}
                                   stroke="var(--neutral-30)"/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

