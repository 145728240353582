import React, {FC, useEffect, useRef, useState} from 'react';
import {EventLogItem} from '../../types/EventLog/EventLogItem';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {GlobalNoticeDetail} from './GlobalNoticeDetail';
import {Alert, AlertProps, ConfigProvider} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

dayjs.extend(localizedFormat);
dayjs.locale('ru');

export type GlobalAlertProps = {
    event: EventLogItem;
    onClose: (id: string) => void
    desc: string;
    type: AlertProps['type'];

};

export const GlobalAlert: FC<GlobalAlertProps> = ({
                                                      event,
                                                      onClose,
                                                      desc,
                                                      type
                                                  }) => {


    const closeIconRef = useRef<HTMLButtonElement | null>(null);

    const [progress, setProgress] = useState(0);


    const NOTIFICATION_LIVE_MS = Number(process.env.REACT_APP_NOTIFICATION_LIVE_MS);
    const step = 50;

    const closeAlertProgrammatically = () => {
        if (closeIconRef.current) {
            closeIconRef.current.click();
        }
    };

    const checkProgress = async (percent: number) => {

        let currentProgress = percent;
        while (currentProgress < 100) {

            currentProgress += 100 / step;
            setProgress(currentProgress);

            await sleep(NOTIFICATION_LIVE_MS / step).then();
        }
        closeAlertProgrammatically();
    }

    function sleep(time: number | undefined) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    useEffect(() => {
        checkProgress(progress)
            .then(() => console.log('alert close ' + event.id));
    }, []);

    const onCloseHandler = (id: string) => {
        onClose(event.id);
    }

    return (
        < >
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#F75623',
                        colorLink: '#F75623',
                    },
                    components: {
                        Progress: {
                            colorBgBase: '#F75623',

                            colorPrimary: '#F75623',
                            algorithm: true
                        }
                    },
                }}>

                <Alert type={type}
                       className={'body_1_long neutral_90'}
                       showIcon
                       onClose={() => onCloseHandler(event.id)}
                       closable
                       closeIcon={
                           <CloseOutlined ref={closeIconRef}/>
                       }
                       message={event?.severity}
                       description={<GlobalNoticeDetail event={event}
                                                        desc={desc}/>}
                />
            </ConfigProvider>

        </>
    )
}
