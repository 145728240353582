/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, {FC, useEffect} from 'react';

import {useLocation} from 'react-router';
import {Aside} from '../../components/Aside';

import styles from './Layout.module.scss';
import ButtonAppBar from '../../components/ButtonAppBar/ButtonAppBar';
import {GlobalNotices} from '../../components/GlobalNotices/GlobalNotices';


type LayoutProps = {
    children: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({children}) => {
    const {pathname} = useLocation();

    useEffect(() => {
        setInnerMainHeight();
    });

    const setInnerMainHeight = () => {
        let inner = document.getElementById("main")?.firstChild as HTMLElement;
        if (inner) {
            inner.style.height = '100%';
            inner.style.maxWidth = 'calc(100vw - 300px)';
        }
    }

    return (
        <>
            {pathname === '/signin'
            || pathname === '/signup'
            || pathname === '/error'
            || pathname === '/tutorial' ? (
                <>{children}</>
            ) : (
                <>
                    <ButtonAppBar/>
                    <div className={styles.content}>
                        {pathname.includes('/monitoring') ? (children) : (<>
                            <aside className={styles.aside}>
                                <Aside pathname={pathname}/>
                            </aside>
                            <main id="main" className={styles.main}>
                                {children}
                            </main>
                        </>)}
                    </div>
                    <GlobalNotices />
                </>
            )}
        </>
    );
};
