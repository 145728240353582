import styles from './Cli.module.scss'
import React, {FC, useEffect, useRef, useState} from 'react';
import {Message} from '../../types/Cli/Message';
import {ExpandAltOutlined} from '@ant-design/icons';
import {Button, ConfigProvider} from 'antd';
import {CliCommandType} from '../../types/Cli/CliCommandType';
import {CliInteractiveOption} from '../../types/Cli/CliInteractiveOption';
import {OutputMessage} from '../../types/Cli/OutputMessage';
import {OutputMessageType} from '../../types/Cli/OutputMessageType';
import {OutputInteractiveMessage} from '../../types/Cli/OutputInteractiveMessage';
import {OutputErrorMessage} from '../../types/Cli/OutputErrorMessage';
import {OutputUpdatePromptMessage} from '../../types/Cli/OutputUpdatePromptMessage';
import {OutputLinesMessage} from '../../types/Cli/OutputLinesMessage';
import {CliOutput} from '../../types/Cli/CliOutput';
import {CliOutputType} from '../../types/Cli/CliOutputType';
import {CliLineOutput} from './CliLineOutput';
import {CliLinesOutput} from './CliLinesOutput';
import {CliJsonListOutput} from './CliJsonListOutput';
import {uuid} from '../../utils';
import {OutputJsonMessage} from '../../types/Cli/OutputJsonMessage';
import {CliJsonSingleOutput} from './CliJsonSingleOutput';
import {OutputCompleteMessage} from '../../types/Cli/OutputCompleteMessage';
import {CliCandidates} from './CliCandidates';
import {readFromLocalStorage, saveToLocalStorage} from '../../StorageUtils';

export type CliProps = {
    isOpen?: boolean;
    isExpand: boolean;
    onInputTerminal: (msg: Message) => void;
    onCliExpand: () => void;
    response?: any;
    interactiveOptions?: CliInteractiveOption[];
};

export const Cli: FC<CliProps> = ({
                                      isOpen,
                                      isExpand,
                                      onInputTerminal,
                                      onCliExpand,
                                      response,
                                      interactiveOptions,

                                  }) => {

    const [input, setInput] = useState("");
    const [output, setOutput] = useState<CliOutput[]>([]);

    const terminalEndRef = useRef<HTMLDivElement | undefined | any>(undefined);
    const [terminalHeight, setTerminalHeight] = useState<string>('250px');
    const [options, setOptions] = useState<CliInteractiveOption[]>(interactiveOptions || []);
    const [filledOptions, setFilledOptions] = useState<CliInteractiveOption[]>([]);

    const [inputType, setInputType] = useState<string | undefined>(undefined);

    const sendMessageKeys: string[] = ['Enter', 'ArrowUp', 'ArrowDown', 'Tab'];

    const [prompt, setPrompt] = useState<string>("damask>");

    const [candidates, setCandidates] = useState<string[]>([]);
    const [isSubCandidate, setSubCandidate] = useState<boolean|undefined>(undefined);

    const scrollToBottom = () => {
        if (terminalEndRef && terminalEndRef.current) {
            terminalEndRef.current.scrollIntoView({behavior: "smooth"});
        }
    };

    const inputRef = useRef<HTMLElement | undefined | any>(undefined);

    useEffect(() => {
        scrollToBottom();
    }, [output]);

    useEffect(()=> {
       let output = readFromLocalStorage('cliOutput');
       setOutput(output);
    },[]);

    useEffect(() => {
        inputRef.current.focus();
    }, [isOpen]);

    useEffect(() => {
        handleCliResponse(response?.body);
    }, [response]);


    useEffect(() => {
        let height = isExpand ? 'calc(100vh - 112px )' : '250px';
        setTerminalHeight(height);
    }, [isExpand]);

    useEffect(() => {
        setOptions(interactiveOptions || []);
    }, [interactiveOptions]);

    useEffect(() => {
        console.log('USE_EFFECT_OPTIONS');
        console.log(options);
        if (options && options.length > 0) {

            let inputType = options[0].hide_input ? 'password' : undefined;
            setInputType(inputType);
        }
        if (options.length === 0) {
            console.log('SEND_USE_EFFECT_OPTIONS');

            let msg = new Message(null, CliCommandType.INTERACTIVE_RESPONSE, filledOptions);
            console.log(msg)
            let cliOutput = new CliOutput(CliOutputType.LINE, msg.command)
            setOutput((prev) => [...prev, cliOutput]);
            onInputTerminal(msg);
            setInputType(undefined);
            setFilledOptions([]);
        }
        saveToLocalStorage('cliOutput', output);
    }, [options]);

    const onKeyDownHandler = (key: string, event: React.KeyboardEvent<HTMLInputElement>) => {
        {
            if (sendMessageKeys.includes(key)) {
                if (key === sendMessageKeys[3]) {
                    event.preventDefault();
                }

                let outVal = '';
                if (options.length === 0) {
                    outVal = prompt + " " + input;
                } else {
                    outVal = options[0].hide_input ? options[0].name : options[0].name + " " + input;
                }

                if (key !== sendMessageKeys[3]) {
                    let cliOutput = new CliOutput(CliOutputType.LINE, outVal);
                    setOutput((prev) => [...prev, cliOutput]);
                    setInput("");
                }
                console.log("Key_handler");
                console.log(options);
                if (options && options.length > 0) {
                    if(key === sendMessageKeys[0]) {
                        let option = new CliInteractiveOption(options[0].name, input);
                        setFilledOptions((prev) => [...prev, option]);
                        let sliceOptions = options.slice(1, options.length + 1);
                        setOptions(sliceOptions);
                        inputRef.current.focus();
                    }
                    if (key === sendMessageKeys[3]) {
                        let val = options[0].name + ' ' + input;
                            onInputTerminal(new Message(val, CliCommandType.COMPLETE));
                    }
                }

                if (options.length === 0 && input.trim().length !== 0) {
                    //TO DO: different type command depends key

                    if (key===sendMessageKeys[0]){
                        onInputTerminal(new Message(input, CliCommandType.COMMAND));
                    }

                    if (key===sendMessageKeys[3]){
                        onInputTerminal(new Message(input, CliCommandType.COMPLETE));
                    }
                } else {
                    if (key===sendMessageKeys[3]){
                        onInputTerminal(new Message(input, CliCommandType.COMPLETE));
                    }
                }
            }
            saveToLocalStorage('cliOutput', output);
        }
    }

    const onCandidateSelect = (key: string, variant: string) => {
        setCandidates([]);


        if (key === 'Enter') {
            let inputVal = '';
            let parts = input.split(' ');

            if (isSubCandidate) {
                let sub = parts.slice(0, 2);
                inputVal = sub.join(' ') + ' ' + variant;
            } else {
                let word = parts[parts.length - 1];
                if (variant.toLowerCase().includes(word)) {
                    parts[parts.length - 1] = variant;
                } else {
                    parts.push(variant);
                }
                parts.forEach((str) => inputVal = inputVal + ' ' + str);
            }
            setInput(inputVal.trim());
        }
        inputRef.current.focus();
    }


    const handleCliResponse = (messageBody?: string) => {
        if (messageBody) {

            let commandResponse = JSON.parse(messageBody) as OutputMessage;
            console.log(commandResponse);
            switch (commandResponse.type) {
                case OutputMessageType.INTERACTIVE :
                    let res = JSON.parse(messageBody) as OutputInteractiveMessage;

                    let cliOutput = new CliOutput(CliOutputType.LINES, res.additional_text)
                    setOutput((prev) => [...prev, cliOutput]);

                    console.log("RECEIVE_INTERACTIVE");
                    console.log(options)
                    setOptions(res.options || []);
                    break;
                case OutputMessageType.ERROR:
                    let error = JSON.parse(messageBody) as OutputErrorMessage;
                    let cliOutputError = new CliOutput(CliOutputType.LINE, prompt + error.text);
                    setOutput((prev) => [...prev, cliOutputError]);

                    break;
                case OutputMessageType.UPDATE_PROMPT:
                    console.log(JSON.parse(messageBody));
                    let promptMsg = JSON.parse(messageBody) as OutputUpdatePromptMessage;
                    if (promptMsg.additional_text) {
                        let cliOutputPrompt = new CliOutput(CliOutputType.LINE, prompt + promptMsg.additional_text);
                        setOutput((prev) => [...prev, cliOutputPrompt]);
                    }

                    setPrompt(promptMsg.text || '');
                    break;
                case OutputMessageType.LINES:
                    let lines = JSON.parse(messageBody) as OutputLinesMessage;

                    let cliOutputLines = new CliOutput(CliOutputType.LINES, lines.lines)
                    setOutput((prev) => [...prev, cliOutputLines]);

                    break;
                case OutputMessageType.JSON_LIST:
                    let jsonList = JSON.parse(messageBody) as OutputJsonMessage;
                    console.log(jsonList);
                    let cliOutputJsonList = new CliOutput(CliOutputType.JSON_LIST, jsonList.json)
                    cliOutputJsonList.is_hide_header = jsonList.is_hide_header;
                    setOutput((prev) => [...prev, cliOutputJsonList]);
                    break;
                case OutputMessageType.JSON_SINGLE:
                    let json = JSON.parse(messageBody) as OutputJsonMessage;
                    console.log(json);
                    let cliOutputJson = new CliOutput(CliOutputType.JSON_SINGLE, json.json)
                    setOutput((prev) => [...prev, cliOutputJson]);
                    break;
                case OutputMessageType.COMPLETE:
                    let cliCompleteMessage = JSON.parse(messageBody) as OutputCompleteMessage;
                    setSubCandidate(cliCompleteMessage.is_sub_candidate);
                    if (cliCompleteMessage.is_single_variant ) {
                        if(cliCompleteMessage.variants) {
                            setInput(cliCompleteMessage.variants[0]);
                        }
                    } else {
                        setCandidates(cliCompleteMessage.variants||[]);
                    }
                    break;
            }
            saveToLocalStorage('cliOutput', output);
        }
    }

    const getCliOutput = (output: CliOutput): any => {

        switch (output.type) {
            case CliOutputType.LINE:
                return <CliLineOutput key={uuid()} line={output.output}/>;
            case CliOutputType.LINES:
                return <CliLinesOutput key={uuid()} lines={output.output}/>;
            case CliOutputType.JSON_LIST:
                return <CliJsonListOutput key={uuid()} jsonData={output.output} is_hide_header={output.is_hide_header} />
            case CliOutputType.JSON_SINGLE:
                return <CliJsonSingleOutput key={uuid()} json={output.output}/>
            default:
                return '';
        }

    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                }
            }}>
            <div className={styles.cli}>

                <div className={styles.terminal} style={{height: terminalHeight}}>
                    {
                        output.map((output) =>
                            getCliOutput(output)
                        )}
                    <div ref={terminalEndRef}/>

                    <div className={styles.terminal_input + ' body_1_long'}>

                        {
                            options.length !== 0 ? options[0].name : prompt
                        }

                        <input className={'body_1_long'}
                               style={{marginLeft: '5px', width: '70%'}}
                               autoFocus={true}
                               ref={inputRef}
                               value={input}
                               type={inputType}
                               onChange={(e) => setInput(e.target.value)}
                               onKeyDown={(e) => onKeyDownHandler(e.key, e)}/>
                        <CliCandidates candidates={candidates} onVariantSelect={onCandidateSelect}/>
                    </div>
                </div>

                <Button
                    className={styles.expand_btn}
                    icon={<ExpandAltOutlined/>}
                    type={'primary'}
                    onClick={onCliExpand}/>

            </div>
        </ConfigProvider>
    )
}
