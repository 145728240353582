import {fetchWithRefresh} from '../auth';
import {optionsGet, optionsPost, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';

export const getEventLog = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/event_log/query`,
    optionsPost(body),
).then(handleHttpResponse);

export const getEventTypes = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/event_log/event_types`,
    optionsGet(),
).then(handleHttpResponse);

export const getSeverityTypes = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/event_log/severity_types`,
    optionsGet(),
).then(handleHttpResponse);


export const getLastEvents = async (limit: number) => fetchWithRefresh(
    `${URL}/v1/event_log/last?limit=${encodeURIComponent(limit)}`,
    optionsGet(),
).then(handleHttpResponse);
