import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Popover, Spin, Tag, Tooltip} from 'antd';
import {i18n, uuid} from '../../utils';
import {BackupRecord} from '../../types/Backup/BackupRecord';
import styles from './BackupStatus.module.scss';
import {BackupActionType} from '../../types/Backup/BackupActionType';

export type BackupStatusProps = {
    status: BackupRecord;
    activeKey: string[];
    action?: BackupActionType;
    rowId?: string;
};

export const BackupStatus: FC<BackupStatusProps> = ({
                                                        status,
                                                        activeKey,
                                                        action,
                                                        rowId
                                                    }) => {

    const [className, setClassName] = useState<string>();
    const [spinning, setSpinning] = useState<boolean>(false);


    const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);

    useEffect(() => {
        setClass();
    }, []);

    useEffect(() => {
        if (action) {
            setSpinning(true);
        } else {
            setSpinning(false);
        }
    }, [action]);

    useEffect(() => {
        setClass();
    }, [status]);

    useEffect(() => {
        setPopoverVisible(false);
        setTooltipVisible(false);
    }, [activeKey]);

    const setClass = () => {
        let className = getClassName(status?.state || '');
        setClassName(className);
    }

    const getClassName = (status: string): string => {
        switch (status) {
            case 'PROCESSING':
            case 'STARTED':
                return 'status_processing';

            case 'FINISHED':
                return 'status_finished';
            case 'ERROR':
                return 'status_error'
            default:
                return 'status_finished';
        }
    }

    const onTagClick = () => {
        setPopoverVisible(!isPopoverVisible);
        setTooltipVisible(!isTooltipVisible);
    }

    const onMouseLeave = () => {
        if (!isPopoverVisible) {
            setTooltipVisible(false);
        }
    }

    const onMouseEnter = () => {
        if (!isPopoverVisible) {
            setTooltipVisible(true);
        }
    }

    const getTitle = (status: BackupRecord): string => {
        let trimText = status.last_error?.substring(0, 25);
        trimText = trimText === undefined ? '' : trimText;
        return trimText + '...click for detail';
    }

    const content = (
        <div>
            <p>{status?.last_error}</p>
        </div>
    );

    const isVisible = (record: BackupRecord): boolean => {
        return record?.state === 'ERROR';
    }

    const getAction = (action: BackupActionType | undefined) => {
        if (action) {
            return i18n('BACKUP_' + action);
        }
        return '';
    }

    const isSpinning = (): boolean => {
        return spinning && rowId === status.request_id
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F75623'
                }
            }}>
            <div>
        <span key={uuid()} className={styles.tag}>
            <Popover content={content}
                     visible={isPopoverVisible && isVisible(status)}
                     title={''}
                     overlayStyle={{width: '50%',}}
                     placement={'leftBottom'}
                     getPopupContainer={() => document.getElementById('backup')!}>

            <Tooltip title={getTitle(status)}
                     visible={(isTooltipVisible && !isPopoverVisible) && isVisible(status)}
                     getPopupContainer={() => document.getElementById('backup')!}>
            <Tag className={className}
                 onClick={onTagClick}
                 onMouseLeave={onMouseLeave}
                 onMouseEnter={onMouseEnter}>
                {i18n('BACKUP_' + status?.state)}
            </Tag>
            </Tooltip>
            </Popover>
            </span>
                <span hidden={!isSpinning()}>
               {getAction(action)} <Spin size="small" spinning={isSpinning()} style={{}}/>
            </span>

            </div>
        </ConfigProvider>
    );
}

