import React, {FC} from 'react';
import {ConfigProvider, Modal, Tabs} from 'antd';
import {DictionaryItem} from '../../types/DictionaryItem';
import {BackupForm} from '../../types/Backup/BackupForm';
import {EventTypeEnum} from '../../types/EventLog/EventTypeуeEnum';
import {EventSeverityComponent} from './EventSeverityComponent';
import {EventType} from '../../types/EventLog/EventType';

export type EventSettingsModalProps = {
    title: string;
    editDictionary?: DictionaryItem | null;
    isModalOpen: boolean;
    onConfirmHandler: (form: BackupForm) => void;
    onCancelHandler: () => void;
    eventTypes: EventType[];
};


export const EventSettingsModal: FC<EventSettingsModalProps> = ({
                                                      title,
                                                      isModalOpen,
                                                      onCancelHandler,
                                                      onConfirmHandler,
                                                                    eventTypes

                                                  }) => {


    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        boxShadow: ' 1px #F75623',
                        colorText: '#23262D',
                        colorTextPlaceholder: '#717681',

                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },
                    Tabs:{
                        colorPrimary: '#F75623',
                        algorithm: true
                    }

                },
            }}>

            <Modal title={<span className={'header_2 neutral_90'}> {title} </span>}

                   open={isModalOpen}
                   width={850}
                   // onOk={onSave}
                   onCancel={onCancelHandler}
                   cancelText={'Отменить'}
                   okText={'Сохранить'}
                   getContainer={() => document.getElementById("event_log")!}>


                <div style={{width: '100%'}}>
                    <Tabs
                        size={'small'}
                        tabPosition={'left'}
                        items={eventTypes.map((eventType) => {
                            const id = String(eventType.name);
                            return {
                                label: `${eventType.description}`,
                                key: id,
                                children: <EventSeverityComponent eventType={eventType}/>,
                            };
                        })}
                    />
                </div>
            </Modal>
        </ConfigProvider>
    )
}
