import React, {FC} from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ru';

dayjs.extend(localizedFormat);
dayjs.locale('ru');

export const ChartTooltip: FC<any> = ({active, payload, type}) => {

    const printValueLabel = (type: string, val: number): string => {
        if(type && val) {
            let label;
            switch (type) {
                case 'disk':
                    label = 'занято: ';
                    break;
                case 'ram':
                    label = 'использовано: ';
                    break;
                case 'cpu':
                    label = 'нагрузка: ';
                    break;
            }

            return label + val.toFixed(2) + ' %';
        } else {
            return '';
        }
    }

    const printTimeLabel = (time: string): string => {
        if (time) {
            let parts = time.split(" ");
            const year = dayjs(parts[0]);
            const yearFormatted = year.format('D MMMM YYYY');
            const [hours, minutes, seconds] = parts[1].split(':').map(Number);
            return yearFormatted + ' ' + dayjs().set('hour', hours).set('minute', minutes).set('second', seconds).format('HH:mm:ss');
        }
        return '';
    }

        if (active && payload && payload.length) {
            return (

                <div style={{backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px'}}>
                    <div className={'body_1_long'}>
                        {printTimeLabel(payload[0].payload.t)}
                    </div>
                    <div className={'body_1_short'}>
                        {printValueLabel(type, payload[0].payload.v)}
                    </div>

                </div>
            );
        }

    return null;
}
