import dayjs from 'dayjs';


export const getMinutes = (val: string): string => {
    if (val) {
        let parts = val.split(" ");
        const [hours, minutes] = parts[1].split(':').map(Number);
        return dayjs().set('hour', hours).set('minute', minutes).format('HH:mm');
    }
    return '';
}

export const getHours = (val: string): string => {
    if (val) {
        let parts = val.split(" ");
        const [hours, minutes] = parts[1].split(':').map(Number);
        return parts[0] + ' ' + dayjs().set('hour', hours).set('minute', minutes).format('HH:mm');
    }
    return '';
}

export const getDays = (val: string): string => {
    if (val) {
        let parts = val.split(" ");
        const [year, month, day] = parts[0].split('-');
        return month + '-' + day;
    }
    return '';
}

export const getMonths = (val: string): string => {
    if (val) {
        let parts = val.split(" ");
        const [year, month, day] = parts[0].split('-');
        return year + '-' + month;
    }
    return '';
}
