import React from 'react';
import styles from './GlobalNotices.module.scss';
import {EventLogItem} from '../../types/EventLog/EventLogItem';
import {AlertProps, ConfigProvider} from 'antd';
import {EventType} from '../../types/EventLog/EventType';
import {find} from 'lodash';
import {getEventTypes} from '../../services/pages/event_log';
import {GlobalAlert} from './GlobalAlert';


export class GlobalNotices extends React.Component<{}, { events: EventLogItem[], eventTypes: EventType[], isDetails: boolean }> {

    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
            eventTypes: [],
            isDetails: false
        };

        getEventTypes().then(resp => {
            this.setState({
                eventTypes: [...resp, ...this.state.eventTypes]
            })
        });

        (window as any).globalNotices = this;
    }


    delGlobalNotice = (id: string) => {
        this.setState({
            events: this.state.events.filter((data: EventLogItem) => data.id !== id),
        });
    };


    addGlobalNotices = (event: EventLogItem[]) => {
        this.setState({
            events: [...event, ...this.state.events],
        });
    }

    getDescription = (name: string): string => {
        let res = '';
        let type = find(this.state.eventTypes, {name: name});
        if (type) {
            res = type.description
        }

        return res;
    }

    getAlertType = (severity: string): AlertProps['type'] => {
        switch (severity) {
            case 'LOG':
                return 'success';
            case 'WARNING':
                return 'warning'

            case 'ERROR':
            case 'PANIC':
            case 'FATAL':
                return 'error'
            default:
                return 'info';
        }
    }

    render() {
        return (

            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#F75623',
                        colorLink: '#F75623',
                    },
                    components: {
                        Alert: {
                            colorPrimary: '#F75623',
                            colorWarning: '#F75623',
                            algorithm: true
                        }
                    },
                }}>
                <span className={styles.notices_list}>

                    {this.state.events.map((data: EventLogItem) => (
                        <span
                            key={data.id}
                            className={`${styles.notice} `}>

                            <GlobalAlert event={data}
                                         onClose={this.delGlobalNotice}
                                         desc={this.getDescription(data?.event)}
                                         type={this.getAlertType(data?.severity)}/>

                        </span>
                    ))}

                </span>

            </ConfigProvider>
        );
    }
}
