import styles from './Backup.module.scss';
import * as React from "react";
import {useEffect, useState} from "react";
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {Card, Collapse, CollapseProps, ConfigProvider} from 'antd';
import {CollapseLabel} from '../../components/CollapseLabel/CollapseLabel';
import {BackupHistory} from './BackupHistory';
import {BackupCommonSettings} from './BackupCommonSettings';
import {cancelBackup, createBackup, deleteBackup, getBackups, getSettings, updateSettings} from '../../services/pages/backup';
import {BackupForm} from '../../types/Backup/BackupForm';
import {BackupRecord} from '../../types/Backup/BackupRecord';
import {BackupModal} from './BackupModal';
import {BackupSettings, toString} from '../../types/Backup/BackupSettings';
import {BackupModeType} from '../../types/Backup/BackupModeType';
import {Dayjs} from 'dayjs';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {getCookie, i18n, setCookie} from '../../utils';
import {BackupActionType} from '../../types/Backup/BackupActionType';


export function Backup() {

    const [backups, setBackups] = useState<BackupRecord[]>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [backupSettings, setBackupsSettings] = useState<BackupSettings>();
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});
    const [activeKey, setActiveKey] = useState<string[]>(['common']);
    const [action, setAction] = useState<BackupActionType | undefined> ();
    const [rowId, setRowId] = useState<string>();

    useEffect(() => {
        getAllBackups();
        getBackupSettings();
       initTabFromCookies();
    }, []);

    useEffect(()=>{
        setCookie('backup', JSON.stringify(activeKey));
    },[activeKey]);

    const getAllBackups = () => {
        getBackups().then(resp => {
            setBackups(resp);
        });
    }

    const getBackupSettings = () => {
        getSettings().then(resp => {
            setBackupsSettings(resp);
        })
    }

    const initTabFromCookies = () => {
        let activeKeys =  getCookie('backup');
        if(activeKeys){
            setActiveKey(JSON.parse(activeKeys));
        }
    }

    const onCreateBackup = () => {
        setModalOpen(true);
    }

    const onSaveSettings = () => {
        if (backupSettings) {
            updateSettings(backupSettings)
                .then((resp) => {
                        handleResponse(resp, 'save')
                    }
                ).catch((exc) => {
                handleResponse(exc, 'save')
            })
        }
    }

    const onConfirmModalHandler = (form: BackupForm) => {
        setActiveKey((prev)=>[...prev, 'progress']);
        createBackup(form).then(resp => {
            getAllBackups();
            handleResponse(resp, 'run');
        }).catch(exc=>{
            handleResponse(exc, 'run');
        });
        setModalOpen(false);
    }

    const onCancelModalOpen = () => {
        setModalOpen(false);
    }

    const onRowActionHandler = (record: BackupRecord) => {
        switch (record.state) {
            case 'FINISHED' :
            case 'ERROR':
                setAction(BackupActionType.DELETE);
                setRowId(record.request_id);
                deleteBackup(record.name || '').then(resp => {
                    getAllBackups();
                    console.log(resp);
                    handleResponse(resp, 'delete');
                    setAction(undefined);
                    setRowId(undefined);
                });
                break;
            case 'PROCESSING' :
            case 'STARTED':
                setAction(BackupActionType.CANCEL);
                setRowId(record.request_id);
                cancelBackup(record.name || '').then(resp => {
                    getAllBackups();
                    console.log(resp);
                    handleResponse(resp, 'cancel');
                    setAction(undefined);
                    setRowId(undefined);
                });
                break;
            default:
                break;
        }

    }

    const onCreationEnd = () => {
        getAllBackups();
    }

    const onModeChange = (mode: BackupModeType) => {
        setBackupsSettings((prev) => ({...prev, mode: mode || BackupModeType.NONE}))
    }

    const onRotationDepthChange = (rotationDepth: string) => {
        if (rotationDepth === '' || (Number(rotationDepth) > 0 && !isNaN(Number(rotationDepth)))) {
            setBackupsSettings((prev) => ({...prev, rotation_depth: Number(rotationDepth)}));
        }
    }

    const onWeekDayChange = (weekDay: number) => {
        setBackupsSettings((prev) => ({...prev, start_weekday: weekDay}));
    }
    const onMonthDayChange = (monthDay: number) => {
        setBackupsSettings((prev) => ({...prev, start_monthday: monthDay}));
    }


    const onTimeChange = (time: Dayjs) => {
        setBackupsSettings((prev) => ({...prev, start_time: toString(time)}))
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_backup_error')  + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_backup_success'),
                show: true
            });
        }
    }

    const handleCollapseChange = (keys: string|string[]) => {

        if (Array.isArray(keys)) {
            setActiveKey(keys)
        } else {
            setActiveKey((prev)=>[...prev, keys]);
        }
    };

    const items: CollapseProps['items'] = [
        {
            key: 'common',
            label: <CollapseLabel text={'Общая информация'}/>,
            children: <BackupCommonSettings backupSettings={backupSettings}
                                            onRotationDepthChange={onRotationDepthChange}
                                            onTimeChange={onTimeChange}
                                            onWeekDayChange={onWeekDayChange}
                                            onMonthDayChange={onMonthDayChange}
                                            onModeChange={onModeChange}/>
        },
        {
            key: 'progress',
            label: <CollapseLabel text={'Ход выполнения'}/>,
            children: <BackupHistory backups={backups}
                                     activeKey={activeKey}
                                     action={action}
                                     rowId={rowId}
                                     onActionHandler={onRowActionHandler}
                                     onCreationEndHandler={onCreationEnd}/>
        }
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        borderRadiusLG: 0,

                    },
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%', overflowY: 'auto'}} key={'backup'} id="backup">
                <div id="backup_page" className={styles.page}>

                    <div className="header_1">

                        {"Бекапирование"}

                        <div className={styles.float_right} id="action_btn">

                            <span style={{marginRight: '10px'}}>

                                <ActionButton btnText={'Сохранить'}
                                              htmlType={'submit'}
                                              type={'default'}
                                              onClickHandler={onSaveSettings}/>
                            </span>


                            <ActionButton btnText={'Запустить'}
                                          htmlType={'submit'}
                                          type={'primary'}
                                          onClickHandler={onCreateBackup}
                                          className={styles.float_right}/>
                        </div>
                    </div>

                    <Collapse items={items}
                              defaultActiveKey={['common']}
                              onChange={(e)=>handleCollapseChange(e)}
                              activeKey={activeKey}
                              bordered={false}
                              ghost={true}/>

                    <div className={styles.form}>

                    </div>

                    <div className={styles.notification}>

                        <BackupModal title={'Запуск бекапирвания'}
                                     isModalOpen={isModalOpen}
                                     onConfirmHandler={onConfirmModalHandler}
                                     onCancelHandler={onCancelModalOpen}/>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
