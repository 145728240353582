import {CliOutput} from './types/Cli/CliOutput';

export const saveToLocalStorage = (key: string, value: CliOutput[]) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const readFromLocalStorage = (key: string): CliOutput[] => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
};

export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}
