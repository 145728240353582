import React, {FC, useState} from 'react';
import {ConfigProvider, Popover, Tooltip} from 'antd';
import styles from './EventData.module.scss';

export type EventDataComponentProps = {
    // status: BackupRecord;
    // activeKey: string[];
    // action?: BackupActionType;
    // rowId?: string;
    eventData: any;
};

export const EventDataComponent: FC<EventDataComponentProps> = ({
                                                                    eventData
                                                                }) => {


    const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);

    const getShortDescription = (eventData: any): string => {

        let stringifyData = JSON.stringify(eventData, null, 2);

        let trimText = stringifyData.substring(0, 25);
        trimText = trimText === undefined ? '' : trimText;

        return trimText + '...';
    }

    const onTagClick = () => {
        setPopoverVisible(!isPopoverVisible);
        setTooltipVisible(!isTooltipVisible);
    }

    const onMouseLeave = () => {
        if (!isPopoverVisible) {
            setTooltipVisible(false);
        }
    }

    const onMouseEnter = () => {
        if (!isPopoverVisible) {
            setTooltipVisible(true);
        }
    }

    const content = (
        <div>
              <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word', background: '#f5f5f5', padding: '10px', borderRadius: '5px'}}>
            {JSON.stringify(eventData, null, 2)} {/* 2 spaces indentation */}
              </pre>
        </div>
    );

    return (
        // <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', background: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
        //     {JSON.stringify(eventData, null, 2)} {/* 2 spaces indentation */}
        // </pre>

        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F75623'
                }
            }}>

            <Popover content={content}
                     visible={isPopoverVisible }
                     title={''}
                     overlayStyle={{width: '50%',}}
                     placement={'leftBottom'}
                     getPopupContainer={() => document.getElementById('event_log')!}>


            <Tooltip title={'Click me for detail...'}
                     visible={isTooltipVisible && !isPopoverVisible}
                     getPopupContainer={() => document.getElementById('event_log')!}>
        <span className={styles.tag}
              onClick={onTagClick}
              onMouseLeave={onMouseLeave}
              onMouseEnter={onMouseEnter}> {
            getShortDescription(eventData)
        }
        </span>
            </Tooltip>
            </Popover>

        </ConfigProvider>

    );
}

