import React, {FC} from 'react';
import {EventLogItem} from '../../types/EventLog/EventLogItem';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import styles from './GlobalNotices.module.scss';

dayjs.extend(localizedFormat);
dayjs.locale('ru');

export type GlobalNoticeDetailProps = {
    event: EventLogItem;
    desc: string;
};

export const GlobalNoticeDetail: FC<GlobalNoticeDetailProps> = ({
                                                                    event,
                                                                    desc
                                                                }) => {


    const renderDate = (time: string): string => {
        if (time) {
            let parts = time.split("T");
            const year = dayjs(parts[0]);
            const yearFormatted = year.format('D MMMM YYYY');
            const [hours, minutes, seconds] = parts[1].slice(0, -1).split(':').map(Number);

            return yearFormatted + ' ' + dayjs().set('hour', hours).set('minute', minutes).set('second', seconds).format('HH:mm:ss');
        }
        return '';
    }


    return (
        <React.Fragment>

            <div className={styles.row}>
                <div>
                    {desc + ' '}
                </div>
                <div>
                    {renderDate(event.received_at)}
                </div>

            </div>

        </React.Fragment>
    )
}
