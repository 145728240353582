import {Card, ConfigProvider, Popover} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {SettingOutlined, SyncOutlined} from '@ant-design/icons';
import styles from './Monitoring.module.scss';
import {MonitoringParam} from '../../types/Manitoring/MonitoringParam';
import {i18n} from '../../utils';
import {getMonitoringMetrics} from '../../services/pages/monitoring';
import {ChartData} from '../../types/Manitoring/ChartData';
import {ChartState} from '../../types/Manitoring/ChartState';
import {MonitoringChartSettingsPopup} from './MonitoringChartSettingPopup';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {ChartTooltip} from '../../components/ChartTooltip/ChartTooltip';
import {getDays, getHours, getMinutes, getMonths} from '../../ChartUtils';


export type MonitoringServerChartProps = {
    className?: string;
    params?: MonitoringParam;
    containerId: string;
    lineColor: string;
    type: string;
    period: string;
};

export const MonitoringServerChart: FC<MonitoringServerChartProps> = ({
                                                                          type,
                                                                          containerId,
                                                                          lineColor,
                                                                          period
                                                                      }) => {

    const {id} = useParams();

    const [chartData, setChartData] = useState<ChartData[]>();

    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

    const [chartState, setChartState] = useState<ChartState>({
        period: 'MINUTE',
        lineColor: lineColor,
        data: []
    });


    useEffect(() => {
        getChartData();
        setChartState((prev) => ({...prev, period: period}));
    }, []);

    useEffect(() => {
        setChartState((prev) => ({...prev, period: period}));
    }, [period]);


    useEffect(() => {
        getChartData();
    }, [chartState.period]);

    useEffect(() => {
        getChartData();
    }, [id]);


    const getChartData = () => {
        getMonitoringMetrics(id || '', type, chartState.period, 100).then((resp) => {
            setChartData(resp.data);
            setChartState((prev) => ({...prev, data: resp.data}));
        });
    }

    const handlePopupSettings = (newOpen: boolean) => {
        setSettingsModalOpen(newOpen);
    };

    const onApplySettings = (color: string) => {
        setChartState((prev) => ({...prev, lineColor: color}));
        setSettingsModalOpen(false);

    }

    const onCancelSettingsHandler = () => {
        setSettingsModalOpen(false);

    }

    const xAxeLabel = (val: ChartData) => {
        switch (chartState.period) {
            case 'MINUTE':
                return getMinutes(val.t);
            case 'HOUR':
                return getHours(val.t);
            case 'DAY':
                return getDays(val.t);
            case 'MONTH':
                return getMonths(val.t);

        }
    }

    const yAxeLabelFormatter = (value: number, index: number): string => {
        return value.toFixed(2) + '%';
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        },
                        Card: {
                            borderRadius: 6,
                            colorPrimaryBorder: '#9BA0AA',
                            algorithm: true,
                        },
                        Radio: {
                            buttonBg: '#E5E7EB',
                            colorBorder: '#E5E7EB',
                            buttonColor: '#717681',
                            borderRadius: 16,
                            buttonCheckedBg: '#E5E7EB',
                            colorPrimaryBorder: '#E5E7EB',
                            buttonPaddingInline: 7
                        }
                    },
                }}>

                <Card bordered={true} style={{width: '594px', height: '312px', margin: '0 10px 0 0'}} key={'server_chart'}>
                    <div className={'header_2'} style={{position: 'absolute', top: '10px', left: '10px', width: '100%'}}>
                        <div>
                            {i18n(type)}
                            <span style={{float: 'right', marginRight: '20px'}} className={'button_2 primary_60'}>
                                <span>
                                    <SyncOutlined className={styles.chart_icon}
                                                  style={{marginRight: '5px'}}
                                                  onClick={getChartData}/> Обновить
                                </span>
                                <span>
                                    <Popover
                                        content={<MonitoringChartSettingsPopup
                                            isOpen={isSettingsModalOpen}
                                            currentLineColor={chartState.lineColor}
                                            onApplySettings={onApplySettings}
                                            onCancelHandler={onCancelSettingsHandler}
                                            containerId={containerId}/>}
                                        getPopupContainer={() => document.getElementById(containerId)!}
                                        placement="bottomRight"
                                        title="Настройки"
                                        trigger="click"
                                        open={isSettingsModalOpen}
                                        onOpenChange={handlePopupSettings}>
                                    <SettingOutlined
                                        className={styles.chart_icon}
                                        style={{marginLeft: '10px', marginRight: '5px', cursor: 'pointer'}}/>
                                    </Popover> Настроить
                                </span>
                        </span>
                        </div>

                    </div>
                    <div style={{marginTop: '10px', marginBottom: '10px'}}>

                    </div>

                    <div style={{height: '230px', marginLeft: '-30px'}} id={containerId}>

                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={chartData}
                                syncId={'monitoring'}
                                margin={{
                                    top: 5,
                                    right: 60,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey={xAxeLabel}/>
                                <YAxis domain={['auto', 'auto']} tickFormatter={yAxeLabelFormatter}/>
                                <Tooltip content={<ChartTooltip type={type}/>}/>
                                <Line type="monotone"
                                      strokeWidth={3}
                                      dataKey="v"
                                      stroke={chartState.lineColor}
                                      dot={false}
                                      activeDot={{r: 5}}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </Card>
            </ConfigProvider>
        </>
    )
}
