import React, {FC} from "react";
import {ConfigProvider, Table} from 'antd';
import {RowTagComponent} from '../../components/RowTagComponent/RowTagComponent';
import {BackupRecord} from '../../types/Backup/BackupRecord';
import {BaselineNode} from '../../types/Backup/BaselineNode';
import {RowActionComponent} from '../../components/RowActionComponent/RowActionComponent';
import {DictionaryItem} from '../../types/DictionaryItem';
import {BackupStatus} from '../../components/BackupStatus/BackupStatus';
import {BackupActionType} from '../../types/Backup/BackupActionType';


export type BackupHistoryProps = {

    editDictionary?: DictionaryItem | null;
    backups: BackupRecord[];
    onActionHandler: (record: BackupRecord) => void;
    onCreationEndHandler: () => void;
    activeKey: string[];
    action?: BackupActionType;
    rowId?:string;

};

export const BackupHistory: FC<BackupHistoryProps> = ({
                                                          backups,
                                                          onActionHandler,
                                                          onCreationEndHandler,
                                                          activeKey,
                                                          action,
                                                          rowId
                                                      }) => {

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'backup_name',
            width: '20%'
        },
        {
            title: 'Начало',
            dataIndex: 'started_at',
            key: 'backup_start_time',
            width: '20%',
        },
        {
            title: 'Завершение',
            dataIndex: 'finished_at',
            key: 'backup_end_time',
            width: '20%'
        },
        {
            title: 'Узлы',
            dataIndex: 'baseline_nodes',
            key: 'backup_baseline_nodes',
            width: '15%',
            render: (record: BaselineNode[]) => {
                return <RowTagComponent record={record}/>
            }
        },
        {
            title: 'Статус',
            key: 'backup_status',
            width: '15%',
            render: (state: BackupRecord) => {
                return <BackupStatus status={state}
                                     action={action}
                                     rowId={rowId}
                                     activeKey={activeKey}/>
            }
        },
        {
            width: '15%',
            key: 'backup_progress',
            render: (record: BackupRecord) => {
                return <RowActionComponent record={record}
                                           action={action}
                                           rowId={rowId}
                                           onCreationHandler={onCreationEndHandler}
                                           onClickHandler={() => onActionHandler(record)}/>
            }
        }
    ]

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>

            <Table
                id="backups_table"
                loading={false}
                rowKey={'request_id'}
                dataSource={backups}
                getPopupContainer={() => document.getElementById('backup')!}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                size="small"/>

        </ConfigProvider>
    );
}
