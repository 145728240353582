import React, {FC} from 'react';
import {ConfigProvider, DatePicker, Select, TreeSelect} from 'antd';
import styles from './EventLog.module.scss';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';
import {EventType} from '../../types/EventLog/EventType';
import {SeverityType} from '../../types/EventLog/SeverityType';
import dayjs, {Dayjs} from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import ruRU from 'antd/lib/locale/ru_RU';
import {EventQueryForm} from '../../types/EventLog/EventQueryForm';
import {DisplayValueType} from 'rc-select/lib/interface';
import {MaxTagPlaceholderComponent} from '../../components/MaxTagPlaceholderComponent/MaxTagPlaceholderComponent';

dayjs.extend(localizedFormat);
dayjs.locale('ru');

export type EventFilterProps = {

    monitoringNodes: MonitoringNode[];
    eventTypes: EventType[];
    severityTypes: SeverityType[];
    queryForm: EventQueryForm;
    onAfterChange: (dateTime: Dayjs) => void
    onBeforeChange: (dateTime: Dayjs) => void
    onEventChange: (eventTypes: string[]) => void;
    onSeverityChange: (severityTypes: string[]) => void;
    onNodeChange: (nodeTypes: string[]) => void;
};


export const EventFilter: FC<EventFilterProps> = ({
                                                      monitoringNodes,
                                                      eventTypes,
                                                      severityTypes,
                                                      queryForm,
                                                      onAfterChange,
                                                      onBeforeChange,
                                                      onEventChange,
                                                      onSeverityChange,
                                                      onNodeChange

                                                  }) => {


    const toDayjs = (dateTime: string | undefined) => {
        if (dateTime) {
            return dayjs(dateTime);
        } else {
            return null;
        }

    }

    const maxTag = (omittedValues: DisplayValueType[]) => {
        return <MaxTagPlaceholderComponent omittedValues={omittedValues} containerId={'event_log'}/>
    }


    return (
        <ConfigProvider
            locale={ruRU}
            theme={{
                token: {
                    colorPrimary: '#F75623',
                    colorLink: '#F75623',
                },
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',

                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },
                    DatePicker: {
                        colorPrimary: '#F75623',
                        colorLink: '#F75623',
                        algorithm: true
                    }
                },
            }}>

            <div id="backup_settings" className={styles.settings} style={{marginTop: '25px'}}>
                <div className={styles.settings_row_text}>

                    <div className={styles.settings_f_cell}>
                        Дата с:

                    </div>

                    <div className={styles.settings_s_cell}>
                        <span>
                        Дата по:

                        </span>
                    </div>

                    <div className={styles.settings_s_cell_event}>
                        <span>
                        Тип события:

                        </span>
                    </div>

                    <div className={styles.settings_s_cell_severity}>
                       <span>
                       Критичность события:

                        </span>


                    </div>

                    <div className={styles.settings_s_cell}>
                       <span>
                       Узел:

                        </span>


                    </div>
                </div>

                <div className={styles.settings_row}>
                    <div className={styles.settings_f_cell}>
                        <DatePicker
                            style={{width: '100%'}}
                            value={toDayjs(queryForm.after)}
                            onChange={onAfterChange}
                            placeholder={'Выберите значение'}
                            getPopupContainer={() => document.getElementById('event_log')!}
                            showTime={true}/>
                    </div>
                    <div className={styles.settings_s_cell}>
                        <DatePicker
                            style={{width: '100%'}}
                            value={toDayjs(queryForm.before)}
                            onChange={onBeforeChange}
                            placeholder={'Выберите значение'}
                            getPopupContainer={() => document.getElementById('event_log')!}
                            showTime={true}/>
                    </div>
                    <div className={styles.settings_s_cell_event}>
                        <TreeSelect treeData={eventTypes.map(type => ({title: type.description, value: type.name, key: type.name}))}
                                    treeCheckable={true}
                                    multiple={true}
                                    maxTagCount={1}
                                    onChange={onEventChange}
                            // filterTreeNode={filterTreeNode}
                                    value={queryForm.event_type}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    maxTagPlaceholder={maxTag}
                                    style={{width: '100%'}}/>

                    </div>
                    <div className={styles.settings_s_cell_severity}>
                        <TreeSelect treeData={severityTypes.map(type => ({title: type.description, value: type.name, key: type.name}))}
                                    treeCheckable={true}
                                    multiple={true}
                                    maxTagCount={1}
                                    onChange={onSeverityChange}
                                    // filterTreeNode={filterTreeNode}
                                    value={queryForm.severity_type}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    maxTagPlaceholder={maxTag}
                                    style={{width: '100%'}}/>


                    </div>

                    <div className={styles.settings_s_cell}>

                        <TreeSelect treeData={monitoringNodes.map(node => ({title: node.node_name, value: node.node_id, key: node.node_id}))}
                                    treeCheckable={true}
                                    multiple={true}
                                    maxTagCount={1}
                                    onChange={onNodeChange}
                                    // filterTreeNode={filterTreeNode}
                                    value={queryForm.node_ids}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    maxTagPlaceholder={maxTag}
                                    style={{width: '100%'}}/>


                    </div>
                </div>
            </div>
        </ConfigProvider>
    )
}
