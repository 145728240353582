import React, {FC, useEffect} from 'react';
import {Popover} from 'antd';
import {DisplayValueType} from 'rc-select/lib/interface';
import {uuid} from '../../utils';

export type MaxTagPlaceholderComponentProps = {

    omittedValues: DisplayValueType[];
    containerId: string;
};

export const MaxTagPlaceholderComponent: FC<MaxTagPlaceholderComponentProps> = ({
                                                                                    omittedValues,
    containerId
                                                                                }) => {
    useEffect(() => {
        console.log('ommited_values');
        console.log(omittedValues);
    }, []);

    return (


        <div id="max_tag_placeholder">
            <Popover key={uuid()} content={omittedValues.map(value => {
                return (<div key={uuid()}><span>{value.label + ' '}</span><br/></div>)
            })} getPopupContainer={() => document.getElementById(containerId)!}>
                <span>+ {omittedValues.length}</span>
            </Popover>
        </div>


    );

}
