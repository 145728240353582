import styles from './EventLog.module.scss';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {Button, Card, ConfigProvider, Tooltip} from 'antd';
import {EventLogJournal} from './EventLogJournal';
import {getEventLog, getEventTypes, getSeverityTypes} from '../../services/pages/event_log';
import {EventLogItem} from '../../types/EventLog/EventLogItem';
import {EventQueryForm} from '../../types/EventLog/EventQueryForm';
import {EventFilter} from './EventFilter';
import {getMonitoringState} from '../../services/pages/monitoring';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';
import {i18n} from '../../utils';
import {SettingOutlined} from '@ant-design/icons';
import {EventSettingsModal} from './EventSettingsModal';
import {EventType} from '../../types/EventLog/EventType';
import {SeverityType} from '../../types/EventLog/SeverityType';
import {Dayjs} from 'dayjs';

export function EventLog() {

    const [events, setEvents] = useState<EventLogItem[]>([]);
    const [monitoringNodes, setMonitoringNodes] = useState<MonitoringNode[]>([]);
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [severityTypes, setSeverityTypes] = useState<SeverityType[]>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [after, setAfter] = useState<Dayjs>();
    const [before, setBefore] = useState<Dayjs>();
    const defaultQuery: EventQueryForm = {};
    const [queryForm, setQueryForm] = useState<EventQueryForm>(defaultQuery);

    useEffect(() => {
        getEvents( );
        getEventType();
        getSeverity();
        getMonitoringNodes();
    }, [])

    const getEvents = ( ) => {
        getEventLog(queryForm).then(resp => {
            console.log(resp);
            setEvents(resp['items']);
            onAfterChange(after);
            onBeforeChange(before);
           // (window as any).globalNotices.addGlobalNotices(events);
        });
    }

    const getMonitoringNodes = () => {
        getMonitoringState().then((resp) => {
            setMonitoringNodes(resp['nodes']);
        });
    }

    const getEventType=()=>{
        getEventTypes().then(resp=>{
            setEventTypes(resp);
        });
    }

    const getSeverity=()=>{
        getSeverityTypes().then(resp=>{
            setSeverityTypes(resp);
        })
    }

    const onClickSetting = () => {
        setModalOpen(true);
    }

    const onCancelSetting = () => {
        setModalOpen(false);
    }

    const onConfirmSettings = () => {
        setModalOpen(false);
    }

    const onAfterChange = (dateTime:Dayjs|undefined) => {
        if(dateTime) {
            setQueryForm((prev) => ({...prev, after: dateTime.toISOString()}));
            setAfter(dateTime);
        } else {
            setQueryForm((prev) => ({...prev, after: undefined}));
            setAfter(undefined);
        }
    }

    const onBeforeChange = (dateTime:Dayjs|undefined) => {
        if(dateTime) {
            setQueryForm((prev) => ({...prev, before: dateTime.toISOString()}));
            setBefore(dateTime);
        }else {
            setQueryForm((prev) => ({...prev, before: undefined}));
            setBefore(undefined);
        }
    }

    const onEventChange = (eventTypes:string[]) => {
        setQueryForm((prev)=> ({...prev, event_type: eventTypes}))
    }

    const onSeverityChange = (severityTypes:string[]) => {
        setQueryForm((prev)=> ({...prev, severity_type: severityTypes}))
    }

    const onNodeChange = (nodeIds:string[]) => {
        setQueryForm((prev)=> ({...prev, node_ids: nodeIds}))
    }

    const onApplyFilter = () => {
        Object.assign(queryForm, {after: after?.format('YYYY-MM-DDTHH:mm:ss[Z]')} );
        Object.assign(queryForm, {before: before?.format('YYYY-MM-DDTHH:mm:ss[Z]')} )
      getEvents();
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F75623',
                    colorLink: '#F75623',
                },
                components: {
                    Collapse: {
                        borderRadiusLG: 0,

                    },
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%', overflowY: 'auto'}} key={'backup'} id="event_log">
                <div id="event_log_page" className={styles.page}>

                    <div className="header_1">

                        {"Журнал событий"}

                        <div className={styles.float_right} id="action_btn">

                            <span style={{marginRight: '10px'}}>
                            <Tooltip title={i18n('event_log_setting')}
                                     getPopupContainer={() => document.getElementById('event_log')!}>
                            <Button
                                icon={<SettingOutlined/>}
                                type={'default'}
                                onClick={onClickSetting}
                            />
                            </Tooltip>
                        </span>


                            <ActionButton btnText={'Применить'}
                                          htmlType={'submit'}
                                          type={'primary'}
                                          onClickHandler={onApplyFilter}
                                          className={styles.float_right}/>
                        </div>
                    </div>



                    <EventFilter monitoringNodes={monitoringNodes}
                                 queryForm={queryForm}
                                 eventTypes={eventTypes}
                                 onAfterChange={onAfterChange}
                                 onBeforeChange={onBeforeChange}
                                 onEventChange={onEventChange}
                                 onSeverityChange={onSeverityChange}
                                 onNodeChange={onNodeChange}
                                 severityTypes={severityTypes}/>

                    <EventLogJournal events={events}
                                     eventTypes={eventTypes}
                                     nodes={monitoringNodes}
                                     severityTypes={severityTypes}/>

                    <div className={styles.form}>

                    </div>

                    <div className={styles.notification}>

                        <EventSettingsModal title={'Настройки уведомлений'}
                                            eventTypes={eventTypes}
                                            isModalOpen={isModalOpen}
                                            onConfirmHandler={onConfirmSettings}
                                            onCancelHandler={onCancelSetting}/>

                            {/*<AntAlert severity={antAlertProps.severity}*/}
                            {/*          content={antAlertProps.content}*/}
                            {/*          onClose={() => alertClose()}*/}
                            {/*          show={antAlertProps.show}/>*/}


                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
