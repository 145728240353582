import React, {FC} from "react";
import {ConfigProvider, Table} from 'antd';
import {BaselineNode} from '../../types/Backup/BaselineNode';
import {EventLogItem} from '../../types/EventLog/EventLogItem';
import {EventDataComponent} from '../../components/EventDataComponent/EventDataComponent';
import {EventType} from '../../types/EventLog/EventType';
import {SeverityType} from '../../types/EventLog/SeverityType';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';
import {find} from 'lodash';
import {RowTagComponent} from '../../components/RowTagComponent/RowTagComponent';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('ru');

export type EventLogJournalProps = {
    events: EventLogItem[];
    eventTypes: EventType[];
    severityTypes: SeverityType[];
    nodes: MonitoringNode[];
};

export const EventLogJournal: FC<EventLogJournalProps> = ({
                                                              events,
                                                              eventTypes,
                                                              severityTypes,
                                                              nodes

                                                          }) => {

    const renderEventType = (eventType:string) :string => {
        let res ='';
        let type = find(eventTypes, {name: eventType});
        if(type) {
            res = type.description
        }

        return res;
    }

    const toBaselineNode = (nodeId: string): BaselineNode[] => {
        let bNodes: BaselineNode[] = [];
        let node = find(nodes, {node_id: nodeId})
        if (node) {
            let baselineNode: BaselineNode = {id: node.node_id, name: node.node_name};
            bNodes.push(baselineNode);
        }

        return bNodes;
    }

    const renderDate = (time: string): string => {
        if (time) {
            let parts = time.split("T");
            const year = dayjs(parts[0]);
            const yearFormatted = year.format('D MMMM YYYY');
            const [hours, minutes, seconds] = parts[1].slice(0, -1).split(':').map(Number);
            console.log(parts[1]);
            console.log(seconds)
            return yearFormatted + ' ' + dayjs().set('hour', hours).set('minute', minutes).set('second', seconds).format('HH:mm:ss');
        }
        return '';
    }


    const columns = [
        {
            title: 'Дата',
            dataIndex: 'received_at',
            key: 'received_at_key',
            width: '20%',
            render:(received_at:string) =>{return renderDate(received_at)}
        },
        {
            title: 'Тип события',
            dataIndex: 'event',
            key: 'event_key',
            width: '20%',
            render:(event_type: string) => {return renderEventType(event_type)}
        },
        {
            title: 'Критичность',
            dataIndex: 'severity',
            key: 'severity_key',
            width: '15%'
        },
        {
            title: 'Узел',
            dataIndex: 'node_id',
            key: 'node_id_key',
            width: '15%',
            render: (node_id: string) => {
                return <RowTagComponent record={toBaselineNode(node_id)}/>
            }
        },
        {
            title: 'Сообщение',
            dataIndex: 'event_data',
            key: 'event_data',
            width: '25%',
            render: (eventData: any) => {
                return <EventDataComponent eventData={eventData}/>
            }
        },
    ]

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>

            <div style={{marginTop: '45px'}}>
                <Table
                    id="event_log_journal"
                    loading={false}
                    rowKey={'id'}
                    dataSource={events}
                    getPopupContainer={() => document.getElementById('event_log')!}
                    columns={columns}
                    pagination={false}
                    scroll={{x: '100%'}}
                    size="small"/>
            </div>
        </ConfigProvider>
    );
}
