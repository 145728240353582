import React, {FC, useEffect, useState} from 'react';
import styles from './RowActionComponent.module.scss';

import {DeleteFilled, StopFilled} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {BackupRecord} from '../../types/Backup/BackupRecord';
import {BackupStatus} from '../../types/Backup/BackupStatus';
import {getBackupStatus} from '../../services/pages/backup';
import {BackupActionType} from '../../types/Backup/BackupActionType';

export type RowActionComponentProps = {
    onClickHandler?: () => void;
    hidden?: boolean;
    record: BackupRecord;
    onCreationHandler: () => void;
    action?: BackupActionType;
    rowId?: string;
};

export const RowActionComponent: FC<RowActionComponentProps> = ({
                                                                    hidden,
                                                                    onClickHandler,
                                                                    onCreationHandler,
                                                                    record,
                                                                    action,
                                                                    rowId
                                                                }) => {

    const notFinalStatus = ['PROCESSING', 'STARTED'];
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (notFinalStatus.includes(record.state || '')) {
            checkProgress(0).then(() => onCreationHandler());
        }
    }, []);

    const checkProgress = async (percent: number) => {

        let currentProgress = percent;
        while (currentProgress < 100) {
            getBackupStatus().then(resp => {
                let status = resp as BackupStatus;
                currentProgress = getProgress(resp);
                if (status.error) {
                    currentProgress = 100;
                }
            })
            await sleep(5000).then();
        }
    }

    const getProgress = (record: BackupStatus): number => {
        let currentProgress = progress;
        if (record.node_metrics) {
            let current = record.node_metrics[0].current_snapshot_processed_size;
            let total = record.node_metrics[0].current_snapshot_total_size;
            currentProgress = total === 0 ? 0 : Math.floor(current / total * 100);
        }
        if (record.error) {
            currentProgress = 100;
        }

        setProgress(currentProgress);
        return currentProgress;
    }

    function sleep(time: number | undefined) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const getIcon = (state: string): any => {
        switch (state) {
            case 'FINISHED' :
            case 'ERROR':
                return <DeleteFilled className={styles.item_icon}
                                     style={{cursor: isAction()?'not-allowed':'pointer' }}
                                     disabled={isAction()}
                                     onClick={onClickHandler}/>;
            case 'PROCESSING' :
            case 'STARTED':
                return <StopFilled className={styles.item_icon}
                                   style={{cursor: isAction()?'not-allowed':'pointer' }}
                                   disabled={isAction()}
                                   onClick={onClickHandler}/>;
            default:
                return '';
        }
    }

    const getTitle = (state: string): any => {
        switch (state) {
            case 'FINISHED' :
            case 'ERROR':
                return 'Удалить бекапирование'
            case 'PROCESSING' :
            case 'STARTED':
                return 'Остановить бекапирование'
            default:
                return '';
        }
    }

    const printProgress = () => {
        return notFinalStatus.includes(record.state || '') ? progress + '%' : '';
    }

    const isAction = (): boolean => {
        return action!==undefined && rowId === record.request_id;
    }

    return (
        <React.Fragment>
            {hidden ? <></> :
                <div id="action_tooltip">
                    <Tooltip title={getTitle(record.state || '')} getPopupContainer={() => document.getElementById('backup')!}>
                        <div className={styles.action_cell}>
                            <div className={styles.percent}>
                                {printProgress()}
                            </div>
                            <div className={styles.action} >
                                {getIcon(record.state || '')}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            }
        </React.Fragment>
    );
}
