import React, {FC} from 'react';
import {EventTypeEnum} from '../../types/EventLog/EventTypeуeEnum';
import {Tabs} from 'antd';
import {SeverityTypeEnum} from '../../types/EventLog/SeverityTypeEnum';
import {EventPairSettings} from './EventPairSettings';
import {EventType} from '../../types/EventLog/EventType';

export type EventSeverityComponentProps = {
    eventData?: any;
    eventType: EventType;
};

export const EventSeverityComponent: FC<EventSeverityComponentProps> = ({
                                                                    eventType
                                                                }) => {



    return (
        <Tabs
            size={'small'}
            tabPosition={'left'}
            items={Object.values(SeverityTypeEnum).map((severityType) => {
                const id = String(severityType);
                return {
                    label: `${severityType}`,
                    key: id,
                    children: <EventPairSettings eventType={eventType} severityType={severityType}/>,
                };
            })}
        />
    );
}

