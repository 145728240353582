import React, {FC} from 'react';
import {SeverityTypeEnum} from '../../types/EventLog/SeverityTypeEnum';
import {ConfigProvider, Select} from 'antd';
import {EventType} from '../../types/EventLog/EventType';

export type EventPairSettingsProps = {
    eventType: EventType
    severityType: SeverityTypeEnum;
};

export const EventPairSettings: FC<EventPairSettingsProps> = ({
                                                                  eventType,
                                                                  severityType
                                                              }) => {

    const options = [
        {label: 'Нет', value: 'not'},
        {label: 'В приложении', value: 'app'},
        {label: 'По почте', value: 'email'}
    ]


    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F75623',
                    colorLink: '#F75623',
                },
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',

                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },
                    Tabs: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>
            <div>
                Настройки пары <br/>
                {eventType.description} - {severityType}
                <Select style={{width: '100%', marginTop: '10px', borderColor: '#F75623'}}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        placeholder={'Выберите значение'}
                        options={options}
                />
            </div>
        </ConfigProvider>
    );
}

